import { useAuthStore } from '~/store/auth.js'

export default function () {
  const { $pinia } = useNuxtApp()
  const config = useRuntimeConfig()
  const store = useAuthStore($pinia)

  const userOrgDetails = () => 
  $fetch(
    `${config.public.apiBaseUrl}/organization-details/user`,
      {
        method: 'GET',
      },
  )
  const orgDetails = () =>
    $fetch(
      `${config.public.apiBaseUrl}/organization-details/${store.user.org_id}`,
      {
        method: 'GET',
      },
    )

  const updateDetails = (data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organization-details/${store.user.org_id}/contact-person`,
      {
        method: 'POST',

        body: data,
      },
    )

  const payment = () =>
    $fetch(`${config.public.apiBaseUrl}/subscription/payment`, {
      method: 'POST',

      body: {
        organizationId: store.user.org_id,
      },
    })

  const subscriptions = () =>
    $fetch(`${config.public.apiBaseUrl}/subscription`, {
      method: 'GET',
      params: {
        organizationId: store.user.org_id,
      },
    })

  const addPlan = (plan) =>
    $fetch(`${config.public.apiBaseUrl}/subscription/add-plan`, {
      method: 'POST',
      body: {
        planId: plan,
        organizationId: store.user.org_id,
      },
    })

  const updatePlan = (plan) =>
    $fetch(`${config.public.apiBaseUrl}/subscription/update`, {
      method: 'POST',
      body: {
        planId: plan,
        organizationId: store.user.org_id,
      },
    })

  const subscription = (n) =>
    $fetch(`${config.public.apiBaseUrl}/subscription/trial`, {
      method: 'GET',
      params: {
        n: n,
      },
    })

  const change = () =>
    $fetch(`${config.public.apiBaseUrl}/subscription/billing-portal`, {
      method: 'GET',
      params: {
        organizationId: store.user.org_id,
      },
    })

  const renew = () =>
    $fetch(`${config.public.apiBaseUrl}/subscription/renew`, {
      method: 'POST',
      params: {
        organizationId: store.user.org_id,
      },
    })

  const getInvoices = (pageNumber) =>
    $fetch(`${config.public.apiBaseUrl}/subscription/invoices`, {
      method: 'GET',
      params: {
        organizationId: store.user.org_id,
        page: pageNumber,
      },
    })

  const cancel = () =>
    $fetch(`${config.public.apiBaseUrl}/subscription/cancel`, {
      method: 'POST',

      body: {
        organizationId: store.user.org_id,
      },
    })

  return {
    userOrgDetails,
    orgDetails,
    subscription,
    payment,
    subscriptions,
    addPlan,
    change,
    getInvoices,
    cancel,
    updateDetails,
    renew,
    updatePlan,
  }
}
